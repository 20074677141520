/* eslint-disable no-unused-vars */
import React from 'react';

const PageHasBeenForceRefreshed = 'page-has-been-force-refreshed';

// eslint-disable-next-line consistent-return
const retryPageLoading = () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(PageHasBeenForceRefreshed) || 'false'
  );

  if (!pageHasAlreadyBeenForceRefreshed) {
    window.localStorage.setItem(PageHasBeenForceRefreshed, 'true');
    return window.location.reload();
    // eslint-disable-next-line no-else-return
  } else {
    window.localStorage.setItem(PageHasBeenForceRefreshed, 'false');
  }
};

// eslint-disable-next-line import/prefer-default-export
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  // eslint-disable-next-line lines-between-class-members, no-unused-vars
  componentDidCatch(error, info) {
    // retryPageLoading();
    this.setState({ hasError: true });
  }
  // eslint-disable-next-line lines-between-class-members
  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      return <div />;
    }
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}
